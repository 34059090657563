import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSupplierStakeHolder,
  deleteSupplierStakeHolder,
  viewSupplierStakeHolder,
} from "../actions/brm/BrmActions";
import { updateUserPosition } from "actions/generalSettings/generalSettingsAction";

function useStakeHolders() {
  const dispatch = useDispatch();
  const { brmReducer, login } = useSelector((state) => state);
  const { selectedCorporate } = useSelector((state) => state?.supplierAdmin);

  const viewStakeholders = () => {
    console.log(login,brmReducer, selectedCorporate)
    dispatch(
      viewSupplierStakeHolder(
        login?.token,
        brmReducer?.supplierOrganizationDetails?._id || login?.supplier_id,
        selectedCorporate?._id || login?.current_organisation || ""
      )
    );
  };

  const deleteStakeholder = (userId) => {
    dispatch(deleteSupplierStakeHolder(login?.token, userId));

    viewStakeholders();
  };

  const updateStakeholderStatus = (token, id, organization, userType) => {
    dispatch(updateUserPosition(token, id, organization, userType, true));
    viewStakeholders();
  };

  const addStakeholder = (
    name,
    email,
    designation,
    category,
    organization_name
  ) => {

   
    dispatch(
      addSupplierStakeHolder(
        login?.token,
        brmReducer?.supplierOrganizationDetails?._id || login?.supplier_id,
        selectedCorporate?._id || "",
        name,
        email,
        designation,
        category,
        organization_name
      )
    );
  };

  return {
    viewStakeholders,
    deleteStakeholder,
    updateStakeholderStatus,
    addStakeholder,
  };
}

export default useStakeHolders;
